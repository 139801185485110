<template>
  <div
    style="height: 100%"
    ref="amenities"
    :class="{ right_content: language == 'ar' }"
  >
    <van-sticky>
      <van-nav-bar
        class="header"
        :title="stringList.maintenance"
        @click-left="onClickLeft"
      >
        <template #left>
          <img
            src="../assets/img/back_new.png"
            style="width: 20px; height: 20px"
          />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="container">
      <div class="form-title">
        <span>{{ stringList.new_request }}</span>
      </div>
      <div class="form">
        <div
          class="form-item required"
          v-for="(item, index) in selectRequestList"
          :key="index"
          :style="{
            'margin-bottom':
              index == selectRequestList.length - 1 &&
              specialRequest.length == 0
                ? '0px'
                : '20px',
          }"
        >
          <van-field
            style="flex: 1"
            v-model="item.keyword"
            readonly
            v-if="language == 'en'"
          ></van-field>
          <van-field
            style="flex: 1"
            v-model="item[language]"
            readonly
            v-else
          ></van-field>
          <img
            src="../assets/img/min.png"
            style="width: 20px; margin-left: 20px"
            @click="removeRequest('1', item.id)"
          />
        </div>
        <div
          v-for="(item, index) in specialRequest"
          :key="index"
          class="form-item required"
          :style="{
            'margin-bottom':
              index == specialRequest.length - 1 ? '0px' : '20px',
          }"
        >
          <van-field
            style="flex: 1"
            type="textarea"
            rows="3"
            @input="validateServiceKey2(index)"
            maxlength="50"
            v-model="specialRequest[index]"
          ></van-field>
          <img
            src="../assets/img/min.png"
            style="width: 20px; margin-left: 20px"
            @click="removeRequest('2', specialRequest[index])"
          />
        </div>
        <div
          class="form-item required"
          v-if="selectRequestList.length == 0 && specialRequest.length == 0"
        >
          <van-field
            readonly
            :placeholder="stringList.select_common_request"
            @click="showRequestPop"
          ></van-field>
        </div>
        <div
          class="form-item3"
          v-if="selectRequestList.length > 0 || specialRequest.length > 0"
        >
          <span
            class="text"
            @click="showRequestPop"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ stringList.more_items }}</span
          >
          <van-icon name="arrow" size="14px" @click="showRequestPop" />
        </div>

        <div class="form-item required" id="roomNumber">
          <a-select
            show-search
            size="large"
            class="roomNum"
            :placeholder="stringList.room_placeholder"
            :getPopupContainer="() => $refs.amenities"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="null"
            @search="roomSearch"
            @change="roomChange"
            :value="roomNumber"
            @focus="clearRoomNumber"
            dropdownClassName="roomNumber"
            :notFoundContent="notFoundContent"
          >
            <template #suffixIcon>
              <img
                style="width: 15px"
                src="@/assets/img/hotel-please-clean.png"
              />
            </template>
            <a-select-option
              v-for="item in roomArray"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>

        <van-row>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :placeholder="stringList.today"
                :value="date"
                @click="() => (showDate = true)"
                :class="{ placeholderColor: date == '' }"
              >
                <template #right-icon>
                  <img
                    src="@/assets/img/calendar-line.png"
                    style="height: 15px"
                  />
                </template>
              </van-field>
            </div>
          </van-col>
          <van-col span="2"><div></div></van-col>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :value="time"
                @click="selectTime"
                :placeholder="stringList.asap"
                :class="{ placeholderColor: time == '' }"
              >
                <template #right-icon>
                  <img src="@/assets/img/clock-time.png" style="height: 15px" />
                </template>
              </van-field>
            </div>
          </van-col>
        </van-row>
        <div class="form-item">
          <van-field
            v-model="notes"
            style="height: auto"
            type="textarea"
            rows="3"
            maxlength="200"
            :placeholder="stringList.comments_placeholder"
          />
        </div>

        <div class="form-item" v-if="showPhone != 0">
          <span
            class="text"
            style="font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            <span
              :class="{ right_text_item: language == 'ar' }"
              style="display: block"
              >{{showPhone === 1 ? stringList.optional1 : stringList.optional3 }}&nbsp;&nbsp;{{ showPhone === 1 ?
                stringList.optional2 : stringList.optional5
              }}</span
            >
          </span>
        </div>
        <div :class="['form-item', { 'required': showPhone === 2 }]" v-if="showPhone != 0">
          <van-field
            v-model="phone"
            type="tel"
            :placeholder="stringList.mobile_number"
            maxlength="12"
            @input="validateMobile"
          ></van-field>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- <div class="btn" @click="submit">
        <span>{{ stringList.submit }}</span>
      </div> -->
      <van-button @click="submit" class="btn">{{
        stringList.submit
      }}</van-button>
    </div>
    <!--  日历控件  -->
    <van-calendar
      v-model="showDate"
      :color="primaryColor"
      :show-title="false"
      :min-date="getMinDate()"
      @confirm="dateConfirm"
      :default-date="getDefaultDate()"
    />

    <!-- 时间选择 -->
    <van-popup v-model="showTime" position="bottom">
      <van-picker
        :columns="timeColumns"
        show-toolbar
        @confirm="timeConfirm"
        @cancel="showTime = false"
      />
    </van-popup>

    <!-- 弹窗 -->
    <van-popup
      v-model="requestPop"
      round
      position="bottom"
      :close-on-click-overlay="true"
      :get-container="() => $refs.amenities"
      @click-overlay="closeRequestPop2"
    >
      <div class="pop">
        <div class="pop-title">
          <span class="title" :class="{ miniFont: language == 'es' }">
            {{ stringList.common_maintenance_list }}
          </span>
          <div class="shopping">
            <van-badge :content="allNum">
              <img src="../assets/img/购物车.png" style="width: 27px" />
            </van-badge>
          </div>
        </div>
        <div class="pop-container">
          <template v-for="item in requestList">
            <div
              class="pop-item"
              v-if="item.qrDisplayGroup && item.qrDisplayGroup == 'Maintenance'"
              :key="item.id"
              @click="addRequest(item)"
            >
              <img
                v-if="item.keyword == 'Air Conditioner'"
                src="../assets/img/kongtiao.png"
              />
              <img
                v-else-if="item.keyword == 'Bathroom Sink'"
                src="../assets/img/xishouchi.png"
              />
              <img
                v-else-if="item.keyword == 'Shower'"
                src="../assets/img/huasa.png"
              />
              <img
                v-else-if="item.keyword == 'Toilet'"
                src="../assets/img/matong.png"
              />
              <img
                v-else-if="item.keyword == 'Tub Drain'"
                src="../assets/img/dilou.png"
              />
              <img
                v-else-if="item.keyword == 'TV'"
                src="../assets/img/dianshitouping.png"
              />
              <img
                v-else-if="item.keyword == 'TV Remote'"
                src="../assets/img/遥控器.png"
              />
              <img
                v-else-if="item.keyword == 'Bathroom Light'"
                src="../assets/img/dengpao.png"
              />
              <img
                v-else-if="item.keyword == 'Clogged Toilet'"
                src="../assets/img/matongxi.png"
              />
              <img
                v-else-if="item.keyword == 'Curtain Rod'"
                src="../assets/img/chuanglian.png"
              />
              <img
                v-else-if="item.keyword == 'Fridge'"
                src="../assets/img/bingxiang.png"
              />
              <img
                v-else-if="item.keyword == 'Microwave'"
                src="../assets/img/weibolu.png"
              />
              <img
                v-else-if="item.keyword == 'Safe'"
                src="../assets/img/strongbox.png"
              />
              <img v-else src="../assets/img/other.png" />
              <span v-if="language == 'en'" class="text">{{
                item.keyword
              }}</span>
              <span
                v-else
                class="text"
                style="margin-right: 10px"
                :class="{ right_text_item: language == 'ar' }"
                >{{ item[language] }}</span
              >
              <div :class="isSelect(item.id) ? 'btn select' : 'btn'">
                <span>{{ stringList.select }}</span>
              </div>
            </div>
          </template>

          <div class="pop-item">
            <img src="../assets/img/other.png" alt="" />
            <span class="text" :class="{ right_text_item: language == 'ar' }">{{
              stringList.other_request
            }}</span>
            <!-- <div :class="specialRequest ? 'btn select' : 'btn'">
              <span>Select</span>
            </div> -->
          </div>
          <div class="other-select">
            <van-field
              v-model="serviceKey"
              style="height: auto"
              type="textarea"
              rows="3"
              maxlength="50"
              :placeholder="stringList.enter_request"
              @input="validateServiceKey"
            />
          </div>
        </div>
        <div class="btn-area">
          <!-- <div class="btn" @click="closeRequestPop">
            <span>{{ stringList.add }}</span>
          </div> -->
          <van-button @click="closeRequestPop" class="btn">{{
            stringList.add
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 确认弹窗 -->
    <van-popup
      v-model="unAbleEngHourPop"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.confirmation }}</span>
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent1 }}
          </div>
          <div>
            <template v-for="item in selectRequestList">
              <div
                class="info-item"
                :key="item.id"
                style="font-weight: 600"
                :class="{ right_keywords_item: language == 'ar' }"
              >
                <span v-if="language == 'en'">{{ item.keyword }}</span>
                <span v-else>{{ item[language] }}</span>
              </div>
            </template>
            <template v-for="item in specialRequest">
              <div
                class="info-item"
                :key="item"
                style="font-weight: 600"
                :class="{ right_keywords_item: language == 'ar' }"
              >
                <span>{{ item }}</span>
              </div>
            </template>
            <!-- <div
              class="info-item"
              v-if="specialRequest"
              style="font-weight: 600"
            >
              <span>{{ specialRequest }}</span>
            </div> -->
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ engHourReponse }}
          </div>
          <div
            class="text"
            style="margin-bottom: 20px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ stringList.confirmationContent }}
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <van-radio-group
              v-model="fdAction"
              icon-size="16"
              direction="horizontal"
              style="
                justify-content: space-between;
                width: 150px;
                margin-bottom: 10px;
              "
            >
              <van-radio shape="square" name="Yes">
                <span>{{ stringList.yes }}</span></van-radio
              >
              <van-radio shape="square" name="No">
                <span>{{ stringList.no }}</span></van-radio
              >
            </van-radio-group>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="unAbleEngHourPopOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="unAbleEngHourPopOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- TransferToFD弹窗 -->
    <van-popup
      v-model="transferToFDPop"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div class="pop-title" style="margin-bottom: 20px">
          <span class="title">{{ stringList.confirmation }}</span>
        </div>
        <div class="pop-info" style="padding: 0 40px">
          <div
            class="text"
            style="font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ transferToFDRequest.response }}
          </div>
          <div
            class="text"
            style="margin-bottom: 20px; font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            <span>{{ stringList.confirmationContent }}</span>
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <van-radio-group
              v-model="transferToFDValue"
              icon-size="14"
              direction="horizontal"
              style="
                justify-content: space-between;
                width: 150px;
                margin-bottom: 10px;
              "
            >
              <van-radio shape="square" name="Yes">
                <span>{{ stringList.yes }}</span></van-radio
              >
              <van-radio shape="square" name="No">
                <span>{{ stringList.no }}</span></van-radio
              >
            </van-radio-group>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="transferToFDPopOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="transferToFDPopOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- TransferToFD或者TransferToRS yes分支弹窗 -->
    <van-popup
      v-model="thankPop2"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.thank }}</span>
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent1 }}
          </div>
          <div>
            <template v-for="item in selectRequestList">
              <div
                class="info-item"
                :key="item.id"
                style="font-weight: 600"
                :class="{ right_keywords_item: language == 'ar' }"
              >
                <span v-if="language == 'en'">{{ item.keyword }}</span>
                <span v-else>{{ item[language] }}</span>
              </div>
            </template>
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent3 }}
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 需要自己去买弹窗answer -->
    <van-popup
      v-model="answerPop"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.thank }}</span>
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.your_request }}
          </div>
          <div
            class="info-item"
            style="margin-bottom: 10px; font-weight: 600"
            :class="{ right_keywords_item: language == 'ar' }"
          >
            <span v-if="language == 'en'">{{ answerRequest.keyword }}</span>
            <span v-else>{{ answerRequest[language] }}</span>
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ okContent }}
          </div>
          <template v-for="(file, index) in fileList">
            <div
              :key="index"
              v-if="showPdf(answerRequest.keyword, file)"
              @click="pdfView(file.fileUrl, file.title)"
              class="text"
              style="text-decoration: underline"
              :class="{ right_text_item: language == 'ar' }"
            >
              {{ JSON.parse(file.title2)[language] }}
            </div>
          </template>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="answerPopOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="answerPopOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- submit弹窗 -->
    <van-popup
      v-model="thankPop"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.thank }}</span>
        </div>
        <div class="pop-info" v-if="fdAction == 'No'">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent1 }}
          </div>
          <div>
            <template v-for="item in selectRequestList">
              <div
                class="info-item"
                :key="item.id"
                style="font-weight: 600"
                :class="{ right_keywords_item: language == 'ar' }"
              >
                <span v-if="language == 'en'">{{ item.keyword }}</span>
                <span v-else>{{ item[language] }}</span>
              </div>
            </template>
            <template v-for="item in specialRequest">
              <div
                class="info-item"
                :key="item.id"
                style="font-weight: 600"
                :class="{ right_keywords_item: language == 'ar' }"
              >
                <span>{{ item }}</span>
              </div>
            </template>
            <!-- <div
              class="info-item"
              v-if="specialRequest"
              style="font-weight: 600"
            >
              <span>{{ specialRequest }}</span>
            </div> -->
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ tips }}
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent2 }}
          </div>
        </div>
        <div class="pop-info" v-else>
          <div class="text" v-if="allNum > 1">
            {{ stringList.responseContent3 }}
          </div>
          <div class="text" v-else>
            {{ stringList.responseContent4 }}
            {{ specialRequest.length > 0 ? specialRequest[0] : "" }}
            <span v-if="language == 'en'">
              {{
                selectRequestList.length > 0
                  ? selectRequestList[0].keyword
                  : ""
              }}.</span
            >
            <span v-else
              >{{
                selectRequestList.length > 0
                  ? selectRequestList[0][language]
                  : ""
              }}.</span
            >
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment-timezone";
import { postAction, getAction } from "@/api/manage";
import { Toast } from "vant";
import { encryptedDES } from "@/utils/DES.js";

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no",
      },
    ],
  },
  name: "Service",
  data() {
    return {
      requestList: [],
      overNightHours: Vue.ls.get("overNightHours"),
      roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : undefined,
      notes: "",
      date: "",
      showDate: false,
      time: "",
      showTime: false,
      roomArray: [],
      phone: "",
      timeColumns: [],

      selectRequestList: [],
      requestPop: true,
      specialRequest: [],
      serviceKey: undefined,
      allNum: 0,

      //防止多次提交
      submiting: false,
      okContent: "",

      isUseEngHour: false,

      //非enghour时段弹窗
      unAbleEngHourPop: false,
      fdAction: "No",
      engHourReponse: Vue.ls.get("engHourReponse"),
      tips: "",
      thankPop: false,

      url: {
        createOrder: "/api/common/hotel/data/qrorder/create",
        multipleCreateOrder: "/api/common/hotel/data/qrorder/multiplecreate",
        idWorker: "/api/common/hotel/data/idWorker",
        login: "/api/common/user/hotel_dept_name/login",
        hotelQuery: "/api/sys_admin/hotel/query",
        hotelinfo: "/adminApi/hotelinfo/infobyhotel",
        getPageInfo: "/adminApi/file/page",
        queryRoomNum: "/api/sys_admin/hotel/room/query2/",
        getKeyWord: "/api/app/current_user/keywordsalldept/",
        getReplyList: "/adminApi/reply/listbyhotel",
        getIsInputPhone: "/adminApi/textconfig/list",
        getFileList: "/adminApi/file/filelist",
        getLanguageList: "/api/common/hotel/data/dict/by_key/languageQr",
        about: "/adminApi/test/qr/about1",
      },
      engRequestTime: Vue.ls.get("engRequestTime"),
      notFoundContent: "",

      //transferToFD流程
      transferToFDPop: false,
      transferToFDValue: "No",
      transferToFDRequest: {},
      thankPop2: false,

      //answer类
      answerRequest: {},
      answerPop: false,
      language: Vue.ls.get("language"),
      fileList: Vue.ls.get("fileList"),
      stringList: {},
      hotelName: Vue.ls.get("inno"),
    };
  },
  watch: {
    serviceKey(n, o) {
      if (n == undefined) return;
      if (n.length >= 50) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width="300px"><span>` +
            this.stringList.limit_request_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
    },
    roomList() {
      // this.roomArray = this.roomList;
    },
    allRequest() {
      if (this.requestList.length == 0) {
        this.requestList = this.allRequest.filter((item) => {
          if (item.section == "transferToFD") {
            console.log(item);
          }
          if (item.serviceType == "Repair") {
            return item;
          }
        });
      }
    },
    selectRequestList() {
      this.allNum = this.selectRequestList.length + this.specialRequest.length;
    },
    specialRequest() {
      this.allNum = this.selectRequestList.length + this.specialRequest.length;
    },
    notes(n, o) {
      if (n.length >= 200 && n.length != 0) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width=300px"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
    },
  },
  computed: {
    ...mapGetters({
      allRequest: "informationRequest", //所有request
      roomList: "roomList",
      showPhone: "showPhone",
      primaryColor: "primaryColor",
      amcniticsRequest: "amcniticsRequest",
      informationRequest: "informationRequest",
    }),
  },
  async created() {
    //无痕浏览器直接进入逻辑
    let tsp = this.$route.query.tsp;
    let now = new Date().getTime();
    console.log(now - tsp * 1000);
    if (now - this.tsp * 1000 > 1000 * 60 * 60 * 24) {
      //如果是走了这个about 后台调用一下接口 打log
      getAction(this.url.about).then((res) => {});
      this.$router.push({ name: "About" });
    } else {
      if (!Vue.ls.get("HTTOKEN")) {
        let params = {
          hotelDeptName: "qrbot",
          hotelName: this.$route.query.inno,
          password: "123456",
        };
        postAction(this.url.login, params).then((res) => {
          if (res.status == 0) {
            this.token = res.data.token;
            Vue.ls.set("HTTOKEN", res.data.token);
            getAction(this.url.getLanguageList).then((res) => {
              Vue.ls.set("languageList", res.data);
            });
            postAction(this.url.hotelQuery, {
              hotelNo: this.$route.query.inno,
            }).then((res) => {
              if (res.status == 0) {
                Vue.ls.set("timeZone", res.data.timeZone, 1000 * 60 * 60 * 24);
                Vue.ls.set("hotelId", res.data.id, 1000 * 60 * 60 * 24);
                //判断是否启动夜间模式
                Vue.ls.set(
                  "overNightHoursInfo",
                  res.data.overNightHours,
                  1000 * 60 * 60 * 24
                );
                // if (
                //   res.data.overNightHours != "disable" &&
                //   res.data.overNightHours != "Regular Operating Hour"
                // ) {
                //   Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                // } else {
                //   Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                // }
                if (
                  res.data.overNightHours == "disable" ||
                  res.data.overNightHours == "Regular Operating Hour"
                ) {
                  Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                } else if (
                  res.data.overNightHours == "Cycle Regular Operating Hour"
                ) {
                  // Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  let now = new Date();
                  let taody = moment(now)
                    .tz(res.data.timeZone)
                    .format("YYYY/MM/DD");
                  let startTime = moment.tz(
                    moment(taody + " " + res.data.fdStartTime).format(
                      "YYYY-MM-DD HH:mm"
                    ),
                    res.data.timeZone
                  );
                  let endTime = moment.tz(
                    moment(
                      res.data.fdEndTime.replace("tomorrow_", taody + " ")
                    ).format("YYYY-MM-DD HH:mm"),
                    res.data.timeZone
                  );
                  if (this.getIsInTimeRange(startTime, endTime)) {
                    Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                  } else {
                    Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  }
                } else {
                  Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                }
                Vue.ls.set(
                  "fdStartTime",
                  res.data.fdStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "fdEndTime",
                  res.data.fdEndTime,
                  1000 * 60 * 60 * 24
                );

                Vue.ls.set("engHour", res.data.engHour, 1000 * 60 * 60 * 24);
                Vue.ls.set(
                  "engEndTime",
                  res.data.engEndTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engStartTime",
                  res.data.engStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engRequestTime",
                  res.data.engRequestTime,
                  1000 * 60 * 60 * 24
                );
                getAction(this.url.hotelinfo, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      this.editColor("#" + r.data.color);
                    }
                  }
                );
                postAction(this.url.getFileList, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      Vue.ls.set(
                        "fileList",
                        r.data.htFileList,
                        1000 * 60 * 60 * 24
                      );
                    }
                  }
                );
                let params = {
                  hotelId: Vue.ls.get("hotelId"),
                  type: "internal",
                };
                getAction(this.url.getReplyList, params).then((res) => {
                  if (res.status == 0) {
                    for (let element of res.data) {
                      if (
                        element.botTrigger == "556" ||
                        element.botTrigger == "557"
                      ) {
                        Vue.ls.set(
                          "fdOption",
                          element.botTriggerValue,
                          1000 * 60 * 60 * 24
                        );
                        Vue.ls.set(
                          "fdOptionReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                    for (let element of res.data) {
                      if (element.botTrigger == "677") {
                        Vue.ls.set(
                          "engHourReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                  }
                });
                let form = {
                  hotelId: Vue.ls.get("hotelId"),
                  size: 10,
                  current: 1,
                };
                postAction(this.url.getIsInputPhone, form).then((res) => {
                  if (res.status == 0) {
                    res.data.records.forEach((item) => {
                      if (Vue.ls.get("type") == "guest") {
                        if (item.requestType == "Guest Room Request") {
                          if (item.textResponse == "Disable") {
                            this.editShowPhone(0);
                            return;
                          }
                          if (item.textResponse == "Enable(Optional)") {
                            this.editShowPhone(1);
                            return;
                          }
                          if (item.textResponse == "Enable(Required)") {
                            this.editShowPhone(2);
                            return;
                          }
                        }
                      } else if (Vue.ls.get("type") == "nonguest") {
                        if (item.requestType == "Meeting Room Request") {
                          if (item.textResponse == "Disable") {
                            this.editShowPhone(0);
                            return;
                          }
                          if (item.textResponse == "Enable(Optional)") {
                            this.editShowPhone(1);
                            return;
                          }
                          if (item.textResponse == "Enable(Required)") {
                            this.editShowPhone(2);
                            return;
                          }
                        }
                      }
                    });
                  }
                });
              }
            });
            if (this.amcniticsRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"fdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editAmcniticsRequest(res.data);
                }
              });
            }
            if (this.informationRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"nonfdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editInformationRequest(res.data);
                }
              });
            }
            if (this.$route.query.type) {
              Vue.ls.set("type", this.$route.query.type, 1000 * 60 * 60 * 24);
              if (Vue.ls.get("type") == "guest") {
                getAction(
                  `${this.url.queryRoomNum}${"guest"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              } else if (Vue.ls.get("type") == "nonguest") {
                getAction(
                  `${
                    this.url.queryRoomNum
                  }${"guMeeting Roomest"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              }
            }
            if (this.$route.query.room) {
              Vue.ls.set("room", this.$route.query.room, 1000 * 60 * 60 * 24);
            }
            if (this.$route.query.inno) {
              Vue.ls.set("inno", this.$route.query.inno, 1000 * 60 * 60 * 24);
            }
          }
        });
        if (!Vue.ls.get("language")) {
          Vue.ls.set("language", "en");
          this.language = "en";
        }
        //判断是否启用enghour
        if (
          Vue.ls.get("engHour") &&
          Vue.ls.get("engHour").indexOf("Cycle") != -1
        ) {
          this.isUseEngHour = true;
        }
        // console.log(this.isUseEngHour);

        this.requestList = this.allRequest.filter((item) => {
          if (item.serviceType == "Repair") {
            return item;
          }
        });
        // this.roomArray = this.roomList;
        // 初始化时间控件
        let hour = [];
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            hour.push("0" + i);
          } else {
            hour.push("" + i);
          }
        }
        this.timeColumns.push({
          values: hour,
        });
        let minte = [];
        for (let i = 0; i <= 59; i++) {
          if (i < 10) {
            minte.push("0" + i);
          } else {
            minte.push("" + i);
          }
        }
        this.timeColumns.push({
          values: minte,
        });
        this.timeColumns.push({
          values: ["AM", "PM"],
        });
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
        this.engHourReponse = JSON.parse(this.engHourReponse)[this.language];
      } else {
        //判断是否启用enghour
        if (
          Vue.ls.get("engHour") &&
          Vue.ls.get("engHour").indexOf("Cycle") != -1
        ) {
          this.isUseEngHour = true;
        }
        // console.log(this.isUseEngHour);

        this.requestList = this.allRequest.filter((item) => {
          if (item.serviceType == "Repair") {
            return item;
          }
        });
        // this.roomArray = this.roomList;
        // 初始化时间控件
        let hour = [];
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            hour.push("0" + i);
          } else {
            hour.push("" + i);
          }
        }
        this.timeColumns.push({
          values: hour,
        });
        let minte = [];
        for (let i = 0; i <= 59; i++) {
          if (i < 10) {
            minte.push("0" + i);
          } else {
            minte.push("" + i);
          }
        }
        this.timeColumns.push({
          values: minte,
        });
        this.timeColumns.push({
          values: ["AM", "PM"],
        });
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
        this.engHourReponse = JSON.parse(this.engHourReponse)[this.language];
      }
    }
  },
  methods: {
    moment,
    ...mapActions([
      "editColor",
      "editAmcniticsRequest",
      "editInformationRequest",
      "editShowPhone",
      "editRoomList",
    ]),
    answerPopOK() {
      if (this.allNum > 0) {
        this.answerPop = false;
        this.requestPop = true;
      } else {
        // this.$router.go(-1);
        let query = {};
        query.type = this.$route.query.type;
        query.inno = this.$route.query.inno;
        if (this.$route.query.room) {
          query.room = this.$route.query.room;
        }
        query.tsp = this.$route.query.tsp;
        this.$router.replace({ name: "Home", query: query });
      }
    },
    pdfView(inno, title) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      let urlType = inno.split(".")[inno.split(".").length - 1];
      //创建订单
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let time1 = new Date().valueOf().toString();
      let params = {
        createTime: createTime.utc().format(),
        fdaction: "",
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelId: Vue.ls.get("hotelId"),
        hotelDeptId: "",
        inquiryNum: "",
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : "GSD",
        requestTime: createTime.utc().format(),
        serviceKey: title ? title : "Guest Service Directory",
        serviceType: "gsd",
        createTimeValue: time1,
        tid: encryptedDES(time1.substring(time1.length - 8)),
        language: this.language,
      };

      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }

      if (
        urlType == "xlsx" ||
        urlType == "docx" ||
        urlType == "pptx" ||
        urlType == "xls" ||
        urlType == "doc" ||
        urlType == "ppt"
      ) {
        window.location.href =
          "https://view.officeapps.live.com/op/view.aspx?src=" + inno;
      } else if (
        urlType == "jpg" ||
        urlType == "png" ||
        urlType == "jpeg" ||
        urlType == "gif"
      ) {
        window.location.href = inno;
      } else if (urlType == "pdf") {
        this.$router.push({
          name: "PdfView",
          query: { inno: inno },
        });
      } else {
        window.location.href = inno;
      }
    },
    showPdf(keyword, file) {
      if (!keyword) return false;
      let services = file.service;
      for (let ss of services.split(",")) {
        if (
          keyword.toLowerCase() == ss.toLowerCase() &&
          file.enable != null &&
          file.enable
        ) {
          return true;
        }
      }
      return false;
    },
    clearRoomNumber() {
      if (this.roomNumber == undefined || this.roomNumber == "") {
        this.roomArray = this.roomList;
      } else {
        this.roomNumber = "";
        this.roomArray = [];
        this.notFoundContent = "";
        this.roomArray = this.roomList;
      }
    },
    onClickLeft() {
      // this.$router.go(-1);
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      query.tsp = this.$route.query.tsp;
      this.$router.replace({ name: "Home", query: query });
    },
    validateServiceKey() {
      if (this.serviceKey == undefined) return;
      if (this.serviceKey.length > 200) {
        this.serviceKey = this.serviceKey.slice(0, 200);
      }
    },
    validateServiceKey2(index) {
      if (this.specialRequest[index] == undefined) return;
      if (this.specialRequest[index].length >= 50) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width="300px"><span>` +
            this.stringList.limit_request_description +
            `</span></div>`,
          type: "html",
        });
      }
    },
    validateMobile(val) {
      //美式mobile 号码格式，3-3-4格式
      //代码参照https://github.com/wuhao000/antd-mobile-vue/blob/master/src/packages/input/src/index.tsx
      let ctrlValue = val;
      ctrlValue = val.replace(/\D/g, "").substring(0, 11);
      const valueLen = ctrlValue.length;
      if (valueLen > 3 && valueLen < 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(3)}`;
      } else if (valueLen >= 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(
          3,
          3
        )}-${ctrlValue.substr(6)}`;
      }
      this.phone = ctrlValue;
      // this.$set(this.requestForm, "phone", ctrlValue);
    },

    roomSearch(value) {
      if (value) {
        this.roomArray = this.roomList.filter((item) => {
          return item.name.toLowerCase().indexOf(value.toLowerCase()) != -1;
        });
        this.notFoundContent = this.stringList.invalid_room;
      } else {
        this.roomArray = [];
        this.notFoundContent = "";
      }
       if (this.roomArray.length == 1) {
        this.roomNumber = this.roomArray[0].name;
        document.querySelector(":focus").blur();
      }
      // console.log(this.roomArray);
    },
    roomChange(value) {
      this.roomNumber = value;
      this.roomArray = this.roomList.filter((item) => {
        return item.name.indexOf(value) != -1;
      });
       if (this.roomArray.length == 1) {
        this.roomNumber = this.roomArray[0].name;
        document.querySelector(":focus").blur();
      }
    },
    dateConfirm(date) {
      // console.log(moment(date.getTime()).format("MM/DD/YYYY"));
      this.date = moment(date.getTime()).format("MM/DD/YYYY");
      // 判断是否为今天
      if (
        this.date !=
        moment(new Date().getTime())
          .tz(Vue.ls.get("timeZone"))
          .format("MM/DD/YYYY")
      ) {
        this.time = "10:00 AM";
      } else {
        this.time = this.stringList.asap;
      }
      this.showDate = false;
    },
    getDefaultDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // console.log(date);
      return new Date(date);
    },
    getMinDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // console.log(date);
      return new Date(date);
    },
    timeConfirm(value) {
      this.showTime = false;
      this.timePickerData = value;
      this.time = value[0] + ":" + value[1] + " " + value[2];
    },
    selectTime() {
      this.showTime = true;
      let time = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("hh:mm A");
      this.timeColumns[0].defaultIndex = Number(time.slice(0, 2)) - 1;
      this.timeColumns[1].defaultIndex = Number(time.slice(3, 5));
      this.timeColumns[2].defaultIndex =
        time.slice(time.length - 2, time.length) == "AM" ? 0 : 1;
    },
    removeRequest(type, value) {
      if (type == "1") {
        this.selectRequestList = this.selectRequestList.filter((item) => {
          return item.id != value;
        });
      } else {
        this.specialRequest = this.specialRequest.filter((item) => {
          return item != value;
        });
      }
    },
    showRequestPop() {
      // if (!this.roomNumber) {
      //   Toast("Please Enter Room #.");
      //   return;
      // }
      this.requestPop = true;
      this.serviceKey = undefined;
    },
    isSelect(id) {
      let target = this.selectRequestList.find((item) => {
        return item.id == id;
      });
      if (target) {
        return true;
      } else {
        return false;
      }
    },
    addSpecalRequest() {
      if (this.serviceKey) {
        this.specialRequest.push(this.serviceKey);
      }
    },
    addRequest(request) {
      let target = this.selectRequestList.find((item) => {
        return item.id == request.id;
      });
      if (target) {
        let index = 0;
        for (let i = 0; i < this.selectRequestList.length; i++) {
          const element = this.selectRequestList[i];
          if (element.id == target.id) {
            index = i;
            break;
          }
        }
        this.selectRequestList.splice(index, 1);
      } else {
        this.selectRequestList.push(request);
        if (
          request.section == "TransferToFD" ||
          request.section == "TransferToRS"
        ) {
          this.transferToFDRequest = request;
          this.transferToFDRequest.response = JSON.parse(request.response)[
            this.language
          ];
          this.requestPop = false;
          this.transferToFDValue = "No";
          this.transferToFDPop = true;
        } else if (request.section == "Answer") {
          this.answerRequest = request;
          this.requestPop = false;
          this.createClosedTicket(request);
        }
      }
    },
    // addMore() {
    //   this.requestPop = true;
    // },
    closeRequestPop() {
      // window.location.hash = "#roomNumber";
      this.addSpecalRequest();
      this.$nextTick(() => {
        if (this.allNum > 0) {
          document.getElementById("roomNumber").scrollIntoView();
          this.requestPop = false;
        }
      });
    },
    closeRequestPop2() {
      document.getElementById("roomNumber").scrollIntoView();
      this.addSpecalRequest();
      this.requestPop = false;
    },
    getIsInTimeRange(startTime, endTime) {
      let now = new Date();
      if (startTime.valueOf() < endTime.valueOf()) {
        return (
          startTime.valueOf() <= now.getTime() &&
          now.getTime() <= endTime.valueOf()
        );
      } else {
        return (
          startTime.valueOf() <= now.getTime() ||
          now.getTime() <= endTime.valueOf()
        );
      }
    },
    transferToFDPopOK() {
      if (this.transferToFDValue == "No") {
        this.createClosedTicket(this.transferToFDRequest);
      } else {
        this.transferToFDPop = false;
        this.requestPop = true;
      }
    },
    createClosedTicket(request) {
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let fdaction = "";
      if (request.section == "Answer") {
        fdaction = "";
      } else if (
        request.section == "TransferToFD" ||
        request.section == "TransferToRS"
      ) {
        fdaction = "No";
      }
      let time1 = new Date().valueOf().toString();
      let params = {
        hotelId: Vue.ls.get("hotelId"),
        createTime: createTime.utc().format(),
        fdaction: fdaction,
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelDeptId: request.hotelDeptId,
        inquiryNum: 1,
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: "None",
        requestTime: createTime.utc().format(),
        serviceKey: request.keyword,
        serviceType: request.serviceType,
        phone: null,
        status: "Complete-Bot",
        createTimeValue: time1,
        tid: encryptedDES(time1.substring(time1.length - 8)),
        language: this.language,
      };
      if (
        Vue.ls.get("overNightHoursInfo") == "Front Desk Hour" ||
        Vue.ls.get("overNightHoursInfo") == "Cycle Front Desk Hour"
      ) {
        params.fdhour = Vue.ls.get("fdOption");
      } else if (
        Vue.ls.get("overNightHoursInfo") == "Cycle Regular Operating Hour"
      ) {
        params.fdhour = "Regular Operating Hour";
      }
      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
              if (request.section == "Answer") {
                this.answerPop = true;
                this.okContent = res.msg;
                let index = 0;
                for (let i = 0; i < this.selectRequestList.length; i++) {
                  const element = this.selectRequestList[i];
                  if (element.id == request.id) {
                    index = i;
                    break;
                  }
                }
                this.selectRequestList.splice(index, 1);
              } else if (
                request.section == "TransferToFD" ||
                request.section == "TransferToRS"
              ) {
                if (this.allNum > 1) {
                  this.transferToFDPop = false;
                  this.requestPop = true;
                } else {
                  // this.$router.go(-1);
                  let query = {};
                  query.type = this.$route.query.type;
                  query.inno = this.$route.query.inno;
                  if (this.$route.query.room) {
                    query.room = this.$route.query.room;
                  }
                  query.tsp = this.$route.query.tsp;
                  this.$router.replace({ name: "Home", query: query });
                }
              }
            } else {
              this.submiting = false;
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
    submit() {
      document.getElementById("roomNumber").scrollIntoView();
      if (!this.roomNumber) {
        Toast(this.stringList.room_toast);
        return;
      }

      if (
        this.selectRequestList.length == 0 &&
        this.specialRequest.length == 0
      ) {
        Toast(this.stringList.service_toast);
        return;
      }
      if (this.showPhone == 2){
        if (!this.phone) {
            Toast(this.stringList.optional4);
            return;
          }
      }
      if (this.phone && this.phone.length !== 12) {
        Toast(this.stringList.phone_toast);
        return;
      }
      // console.log(this.selectRequestList);
      //判断是否处于enghour时段内
      //先判断是否只有 TransferToFD
      let target = this.selectRequestList.find((i) => {
        return i.section != "TransferToFD" && i.section != "TransferToRS";
      });
      if (target || this.specialRequest.length > 0) {
        if (this.isUseEngHour) {
          let flag = false;
          let now = new Date();
          let today = moment(now)
            .tz(Vue.ls.get("timeZone"))
            .format("YYYY/MM/DD");
          let startTime = moment.tz(
            moment(today + " " + Vue.ls.get("engStartTime")).format(
              "YYYY-MM-DD HH:mm"
            ),
            Vue.ls.get("timeZone")
          );
          let endTime = moment.tz(
            moment(
              Vue.ls.get("engEndTime").replace("tomorrow_", today + " ")
            ).format("YYYY-MM-DD HH:mm"),
            Vue.ls.get("timeZone")
          );

          if (this.getIsInTimeRange(startTime, endTime)) {
            flag = true;
          }
          // eslint-disable-next-line no-constant-condition
          if (!flag) {
            //正常流程
            this.createOrder("");
          } else {
            //特殊流程弹窗
            this.fdAction = "No";
            this.unAbleEngHourPop = true;
          }
        } else {
          //正常流程
          this.createOrder("");
        }
      } else {
        //正常流程
        this.createOrder("");
      }
    },
    unAbleEngHourPopOK() {
      this.unAbleEngHourPop = false;
      let requestTime = "";
      if (this.fdAction == "No") {
        let now = new Date();
        let tomorrow = moment(now.getTime() + 1000 * 60 * 60 * 24)
          .tz(Vue.ls.get("timeZone"))
          .format("YYYY/MM/DD");

        requestTime = moment.tz(
          moment(
            this.engRequestTime.replace("tomorrow_", tomorrow + " ")
          ).format("YYYY-MM-DD HH:mm"),
          Vue.ls.get("timeZone")
        );
      }
      this.createOrder(requestTime);
    },
    async createOrder(requestTime) {
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));

      //请求orderId
      let orderId;
      await getAction(this.url.idWorker).then((res) => {
        orderId = res.data;
      });

      //确定requestTime
      let today = createTime.format("MM/DD/YY");
      let tomorrow = moment(createTime.valueOf() + 1000 * 60 * 60 * 24)
        .tz(Vue.ls.get("timeZone"))
        .format("MM/DD/YY");

      let date = this.date;
      if (!date) {
        date = moment(new Date())
          .tz(Vue.ls.get("timeZone"))
          .format("YYYY/MM/DD");
      }
      let time = this.time;
      if (!time || time == this.stringList.asap) {
        time = moment(new Date()).tz(Vue.ls.get("timeZone")).format("hh:mm A");
      }
      if (!requestTime) {
        requestTime = moment.tz(
          moment(date + " " + time).format("YYYY-MM-DD HH:mm"),
          Vue.ls.get("timeZone")
        );
        if (!this.date && !this.time) {
          this.tips = this.stringList.tipsContent1;
        } else {
          this.tips =
            this.stringList.tipsContent4 +
            requestTime.format("MM/DD/YY hh:mm A") +
            ".";
        }
      } else {
        if (requestTime.format("MM/DD/YY") == today) {
          this.tips = this.stringList.tipsContent6;
        } else if (requestTime.format("MM/DD/YY") == tomorrow) {
          this.tips = this.stringList.tipsContent5;
        } else {
          this.tips =
            this.stringList.tipsContent4 + requestTime.format("MM/DD/YY") + ".";
        }
      }

      if (requestTime.valueOf() + 180000 < now.getTime()) {
        Toast(this.stringList.time_toast);
        return;
      }
      // if (!this.phone || this.phone.length !== 12) {
      //   Toast(this.stringList.phone_toast);
      //   return;
      // }
 

      // console.log(this.selectRequestList);
      let params = [];
      for (let i = 0; i < this.selectRequestList.length; i++) {
        const request = this.selectRequestList[i];
        console.log(request);
        let fdAction = "";
        if (
          request.section == "TransferToFD" ||
          request.section == "TransferToRS"
        ) {
          fdAction = "Yes";
        } else {
          fdAction = !this.isUseEngHour ? "" : this.fdAction;
        }
        let time = new Date().valueOf().toString();
        let param = {
          hotelId: Vue.ls.get("hotelId"),
          fdaction: fdAction,
          orderId: orderId,
          createTime: createTime.utc().format(),
          fdhour: Vue.ls.get("overNightHoursInfo"),
          hotelDeptId: request.hotelDeptId,
          inquiryNum: "",
          notes: "",
          requestType: Vue.ls.get("type"),
          roomNumber: this.roomNumber,
          requestTime: requestTime.utc().format(),
          serviceKey: request.keyword,
          serviceType: request.serviceType,
          phone: this.phone,
          createTimeValue: time,
          tid: encryptedDES(time.substring(time.length - 8)),
          language: this.language,
        };
        if (
          Vue.ls.get("overNightHoursInfo") == "Front Desk Hour" ||
          Vue.ls.get("overNightHoursInfo") == "Cycle Front Desk Hour"
        ) {
          param.fdhour = Vue.ls.get("fdOption");
        } else if (
          Vue.ls.get("overNightHoursInfo") == "Cycle Regular Operating Hour"
        ) {
          param.fdhour = "Regular Operating Hour";
        }
        params.push(param);
      }
      for (let i = 0; i < this.specialRequest.length; i++) {
        const request = this.specialRequest[i];
        let time = new Date().valueOf().toString();
        let param = {
          hotelId: Vue.ls.get("hotelId"),
          fdaction: !this.isUseEngHour ? "" : this.fdAction,
          orderId: orderId,
          createTime: createTime.utc().format(),
          fdhour: Vue.ls.get("overNightHoursInfo"),
          hotelDeptId: null,
          inquiryNum: "",
          notes: "",
          requestType: "",
          roomNumber: this.roomNumber,
          requestTime: requestTime.utc().format(),
          serviceKey: request,
          serviceType: "Maintenance",
          phone: this.phone,
          createTimeValue: time,
          tid: encryptedDES(time.substring(time.length - 8)),
          language: this.language,
        };
        if (
          Vue.ls.get("overNightHoursInfo") == "Front Desk Hour" ||
          Vue.ls.get("overNightHoursInfo") == "Cycle Front Desk Hour"
        ) {
          param.fdhour = Vue.ls.get("fdOption");
        } else if (
          Vue.ls.get("overNightHoursInfo") == "Cycle Regular Operating Hour"
        ) {
          param.fdhour = "Regular Operating Hour";
        }
        params.push(param);
      }
      params[0].notes = this.notes;
      if (this.notes.length > 200 && this.notes.length != 0) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width=300px"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
      //额外生成一个frontoffice callback
      if(this.isUseEngHour&&this.fdAction=="Yes"){
        let time = new Date().valueOf().toString();
        let param = {
          hotelId: Vue.ls.get("hotelId"),
          fdaction: !this.isUseEngHour ? "" : this.fdAction,
          orderId: orderId,
          createTime: createTime.utc().format(),
          fdhour: Vue.ls.get("overNightHoursInfo"),
          hotelDeptId: null,
          inquiryNum: "",
          notes: "",
          requestType: "guest",
          roomNumber: this.roomNumber,
          requestTime: requestTime.utc().format(),
          serviceKey: "Talk To A Person",
          serviceType: "CallBack",
          phone: this.phone,
          createTimeValue: time,
          tid: encryptedDES(time.substring(time.length - 8)),
          language: this.language,
        };
        params.push(param);
      }
      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.multipleCreateOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
              // console.log(this.selectRequestList);
              let target = this.selectRequestList.find((i) => {
                return (
                  i.section != "TransferToFD" && i.section != "TransferToRS"
                );
              });
              if (target || this.specialRequest.length > 0) {
                this.thankPop = true;
              } else {
                this.thankPop2 = true;
              }
            } else {
              this.submiting = false;
              Toast(res.msg);
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right_keywords_title {
  display: grid !important;
  text-align: right !important;
}
.right_keywords_item {
  text-align: right !important;
}
.miniFont {
  font-size: 16px !important;
}
.right_text_item {
  text-align: right !important;
}
.right_content {
  /deep/.ant-select-selection__placeholder {
    text-align: right !important;
  }
  /deep/.van-field__control {
    text-align: right !important;
  }
  /deep/.ant-select {
    text-align: right !important;
    direction: rtl;
  }
  /deep/.ant-select-dropdown-menu-item {
    text-align: right !important;
  }
}
.header {
  /deep/.van-nav-bar__content {
    height: 50px;
    background-color: #f3f3f3;
    .van-nav-bar__title {
      font-size: 18px;
      line-height: 20px;
      font-family: $fontFamily;
      color: #4f4f4f;
      font-weight: 400;
    }
    .van-nav-bar__arrow {
      color: #bbbbbb;
      font-size: 22px;
    }
  }
}

.bottom {
  height: 104px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .btn {
    height: 47px;
    width: 167px;
    border-radius: 20px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    span {
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
      color: #ffffff;
    }
  }
}

.container::-webkit-scrollbar {
  display: none;
}

.container {
  height: calc(100% - 155px);
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  overflow-y: auto;

  .form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    word-break: normal;
    position: relative;
    margin-bottom: $form-title-margin-bottom;

    span {
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  }

  .form {
    padding-left: 10px;
    word-break: normal;

    .required {
      position: relative;

      &:before {
        position: absolute;
        content: "*";
        left: -15px;
        width: 15px;
        height: 40px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-item {
      font-size: 15px;
      word-break: normal;
      text-align: left;
      margin-bottom: 20px;
      line-height: 17px;
      font-family: $fontFamily;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        min-height: 40px;
        border-radius: 6px;
        color: #9a9a9a;
        background-color: #f3f3f3;
        width: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 100%;
        font-family: $fontFamily;
        line-height: 17px;
        font-size: 15px;

        ::-webkit-input-placeholder {
          color: #9a9a9a !important;
        }
      }
      .placeholderColor {
        ::-webkit-input-placeholder {
          color: #323233 !important;
        }
      }

      .text {
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #4f4f4f;
      }

      .roomNum {
        width: 100%;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #323233;
      }

      /deep/.van-field--min-height .van-field__control {
        min-height: auto;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }

      /deep/textarea {
        padding: 10px 0 !important;
      }
    }

    .form-item2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        width: 215px;
      }

      .van-stepper {
        display: flex;
        flex-flow: nowrap;
      }

      /deep/.van-stepper--round .van-stepper__minus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }

      /deep/.van-stepper--round .van-stepper__plus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }
    }

    .form-item2:last-child {
      margin-bottom: 0px;
    }

    .form-item3 {
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;

      span {
        line-height: 16px;
        font-size: 14px;
        word-break: normal;
        font-family: $fontFamily;
        color: #1e4380;
      }
    }
  }
}

/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.van-popup--bottom.van-popup--round {
  border-radius: 12px 12px 0 0;
}

.van-popup--center.van-popup--round {
  border-radius: 6px;
}

.pop {
  word-break: normal;
  text-align: left;
  max-height: 80vh;

  .pop-title {
    margin: 0 30px;
    height: 60px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 18px;
      line-height: 20px;
      color: #1e4380;
      font-weight: 600;
    }

    .icon {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      left: 0px;
    }

    .shopping {
      // font-size: 30px;
      color: #bbbbbb;
      position: absolute;
      right: 0px;
    }

    .van-badge__wrapper {
      display: flex;
    }

    /deep/.van-badge--fixed {
      top: 5px;
      // right: 5px;
    }
  }

  .pop-title3 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    // justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-title2 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-container {
    padding: 0 30px;
    max-height: calc(80vh - 160px);
    overflow-y: auto;

    .pop-item {
      // height: $pop-item-height;
      min-height: 50px;
      padding: 10px 0;
      border-top: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 22px;
        margin-right: 25px;
      }
      .text {
        flex: 1;
        color: #000000;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
      }
      .icon {
        font-size: 18px;
        color: #bbbbbb;
      }

      .select {
        background-color: $primary-color;
        border: 1px solid $primary-color !important;
        color: #ffffff !important;
      }

      .btn {
        height: 25px;
        width: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid #bbbbbb;
        color: #4f4f4f;

        span {
          font-size: 14px;
          line-height: 16px;
          font-family: $fontFamily;
        }
      }

      &:first-child {
        border-top: 0px;
      }
    }

    .other-select {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 20px;
      padding-left: 50px;

      .van-field {
        // margin-left: 50px;
        min-height: 40px;
        border-radius: 6px;
        color: #9a9a9a;
        background-color: #f3f3f3;
        width: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 15px;
        line-height: 17px;

        ::-webkit-input-placeholder {
          color: #9a9a9a !important;
        }
      }

      .text {
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #4f4f4f;
      }

      /deep/textarea {
        padding: 10px 0 !important;
      }
    }

    .van-stepper {
      display: flex;
      flex-flow: nowrap;
    }

    /deep/.van-stepper--round .van-stepper__minus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }

    /deep/.van-stepper--round .van-stepper__plus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }
  }

  .pop-container::-webkit-scrollbar {
    display: none;
  }

  .btn-area {
    height: 100px;
    border-top: 2px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 47px;
      width: 167px;
      border-radius: 20px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 18px;
        font-family: $fontFamily;
        color: #ffffff;
      }
    }
  }

  .pop-info {
    padding: 0 30px;
    .text {
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      font-family: $fontFamily;
      margin-bottom: 10px;
    }

    .info-item {
      padding-left: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      font-family: $fontFamily;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
        left: 0px;
        top: 7px;
      }
    }

    .info-item:last-child {
      margin-bottom: 20px;
    }

    /deep/.van-radio__label {
      font-size: 15px;
      line-height: 17px;
      color: #333333;
      font-family: $fontFamily;
    }

    /deep/.van-radio__icon--checked .van-icon {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

/deep/.ant-select-dropdown {
  z-index: 9999;
  text-align: left;
}
/deep/.ant-select-dropdown-menu-item {
  font-size: 17px;
  color: #000000;
  line-height: 24px;
  font-family: $fontFamily;
}
/deep/.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-selected {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}

/deep/.ant-select-dropdown-menu-item-disabled {
  color: #bbbbbb;
}

/deep/ .roomNumber .ant-select-dropdown-menu {
  max-height: 250px !important;
}
/deep/.van-field__body {
  line-height: 20px;
}
</style>
